import React from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Inicio from './Views/Inicio'
import TerminosPaciente from './Views/TerminosPaciente'
import AvisoPaciente from './Views/AvisoPaciente'
import TerminosMedico from './Views/TerminosMedico'
import AvisoMedico from './Views/AvisoMedico'
import TerminosGerente from './Views/TerminosGerente'
import AvisoGerente from './Views/AvisoGerente'

import Loader from './Components/Layout/Loader'

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Loader />
                    <Inicio />
                </Route>
                <Route exact path="/terminos/paciente">
                    <Loader />
                    <TerminosPaciente />
                </Route>
                <Route exact path="/aviso/paciente">
                    <Loader />
                    <AvisoPaciente />
                </Route>
                <Route exact path="/terminos/medico">
                    <Loader />
                    <TerminosMedico />
                </Route>
                <Route exact path="/aviso/medico">
                    <Loader />
                    <AvisoMedico />
                </Route>
                <Route exact path="/terminos/gerente">
                    <Loader />
                    <TerminosGerente />
                </Route>
                <Route exact path="/aviso/gerente">
                    <Loader />
                    <AvisoGerente />
                </Route>
                <Route path="/">
                    <Loader />
                    <Inicio />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
