import React, { Fragment } from 'react';

const Aviso = () => {
    
    return (
    	<Fragment>
    		Biogenica
	    </Fragment>
    )
}

export default Aviso;