import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const AvisoPaciente = () => {
    
    return (
    	<Container fluid className="mt-3 px-4">
            <Row>
                <Col xs={12}>
                    <h1 className="py-3">Aviso de privacidad</h1>
                </Col>
                <Col xs={12}>
                    <p className="contenido_nosotros">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lobortis, velit in mollis placerat, risus tortor aliquam magna, id egestas nibh quam id mauris. Ut in massa sed urna pharetra interdum. Praesent varius lectus sed facilisis consectetur. Integer sed ligula sem. Phasellus fermentum ligula ut lectus ultrices pretium.
                    </p>
                    <p className="contenido_nosotros">
                        Ut finibus enim suscipit tempus aliquet. Aenean sagittis gravida sapien sed rutrum. Sed posuere volutpat condimentum. Nulla elementum erat quis nulla ullamcorper ultrices. Nulla efficitur blandit laoreet. Aenean sodales, nisl eu efficitur cursus, est tortor efficitur nulla, vitae porta eros tellus pulvinar magna.
                    </p>
                    <h5>Aliquam placerat consequat gravida.</h5>
                    <p className="contenido_nosotros">Nam est quam, sodales vitae lacus a, scelerisque vulputate elit. Suspendisse viverra ullamcorper ex, at mattis mi fringilla eu. In ut malesuada arcu:</p>
                    <ul>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                    </ul>
                    <h5>Aliquam placerat consequat gravida.</h5>
                    <p className="contenido_nosotros">Nam est quam, sodales vitae lacus a, scelerisque vulputate elit. Suspendisse viverra ullamcorper ex, at mattis mi fringilla eu. In ut malesuada arcu:</p>
                    <ul>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                    </ul>
                    <h5>Aliquam placerat consequat gravida.</h5>
                    <p className="contenido_nosotros">Nam est quam, sodales vitae lacus a, scelerisque vulputate elit. Suspendisse viverra ullamcorper ex, at mattis mi fringilla eu. In ut malesuada arcu:</p>
                    <ul>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                    </ul>
                    <h5>Aliquam placerat consequat gravida.</h5>
                    <p className="contenido_nosotros">Nam est quam, sodales vitae lacus a, scelerisque vulputate elit. Suspendisse viverra ullamcorper ex, at mattis mi fringilla eu. In ut malesuada arcu:</p>
                    <ul>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                    </ul>
                    <h5>Aliquam placerat consequat gravida.</h5>
                    <p className="contenido_nosotros">Nam est quam, sodales vitae lacus a, scelerisque vulputate elit. Suspendisse viverra ullamcorper ex, at mattis mi fringilla eu. In ut malesuada arcu:</p>
                    <ul>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                        <li>Vivamus</li>
                        <li>Donec</li>
                        <li>Vestibulum</li>
                        <li>Etiam</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}

export default AvisoPaciente;